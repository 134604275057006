import { j as y } from "./jsx-runtime-D0AQ1Mn7.js";
import { _ as R, a as N } from "./index-CWsRuimy.js";
import * as K from "react";
import { forwardRef as h, createElement as i, useEffect as S, useRef as w, useState as P, useCallback as E } from "react";
import { b as x, a as U, $ as ie } from "./index-Bkga1U-V.js";
import { $ as D } from "./index-BFOaq7n_.js";
import { $ as F } from "./index-BEhJEO-h.js";
import { $ as fe } from "./index-DHz2jl-b.js";
import { $ as ue } from "./index-iYICMc2K.js";
import { $ as V } from "./index-U5S6G_il.js";
import { $ as be } from "./index-BbCpSGJn.js";
import { $ as le } from "./index-CX8l_p9w.js";
import { $ as $e } from "./index-CaW1c6EJ.js";
import { c as pe } from "./index-CYs0Zmg8.js";
import { c as q } from "./utils-CJ9afRe1.js";
import { I as me } from "./Icon-vWfhOtJ0.js";
const T = "rovingFocusGroup.onEntryFocus", ve = {
  bubbles: !1,
  cancelable: !0
}, O = "RovingFocusGroup", [G, j, Re] = fe(O), [ge, B] = D(O, [
  Re
]), [Ie, xe] = ge(O), he = /* @__PURE__ */ h((e, t) => /* @__PURE__ */ i(G.Provider, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ i(G.Slot, {
  scope: e.__scopeRovingFocusGroup
}, /* @__PURE__ */ i(_e, R({}, e, {
  ref: t
}))))), _e = /* @__PURE__ */ h((e, t) => {
  const { __scopeRovingFocusGroup: o, orientation: r, loop: n = !1, dir: c, currentTabStopId: u, defaultCurrentTabStopId: p, onCurrentTabStopIdChange: f, onEntryFocus: s, ...v } = e, l = w(null), $ = N(t, l), d = V(c), [a = null, b] = U({
    prop: u,
    defaultProp: p,
    onChange: f
  }), [C, g] = P(!1), I = ie(s), re = j(o), k = w(!1), [ne, M] = P(0);
  return S(() => {
    const m = l.current;
    if (m)
      return m.addEventListener(T, I), () => m.removeEventListener(T, I);
  }, [
    I
  ]), /* @__PURE__ */ i(Ie, {
    scope: o,
    orientation: r,
    dir: d,
    loop: n,
    currentTabStopId: a,
    onItemFocus: E(
      (m) => b(m),
      [
        b
      ]
    ),
    onItemShiftTab: E(
      () => g(!0),
      []
    ),
    onFocusableItemAdd: E(
      () => M(
        (m) => m + 1
      ),
      []
    ),
    onFocusableItemRemove: E(
      () => M(
        (m) => m - 1
      ),
      []
    )
  }, /* @__PURE__ */ i(F.div, R({
    tabIndex: C || ne === 0 ? -1 : 0,
    "data-orientation": r
  }, v, {
    ref: $,
    style: {
      outline: "none",
      ...e.style
    },
    onMouseDown: x(e.onMouseDown, () => {
      k.current = !0;
    }),
    onFocus: x(e.onFocus, (m) => {
      const ae = !k.current;
      if (m.target === m.currentTarget && ae && !C) {
        const L = new CustomEvent(T, ve);
        if (m.currentTarget.dispatchEvent(L), !L.defaultPrevented) {
          const A = re().filter(
            (_) => _.focusable
          ), ce = A.find(
            (_) => _.active
          ), se = A.find(
            (_) => _.id === a
          ), de = [
            ce,
            se,
            ...A
          ].filter(Boolean).map(
            (_) => _.ref.current
          );
          z(de);
        }
      }
      k.current = !1;
    }),
    onBlur: x(
      e.onBlur,
      () => g(!1)
    )
  })));
}), we = "RovingFocusGroupItem", Ce = /* @__PURE__ */ h((e, t) => {
  const { __scopeRovingFocusGroup: o, focusable: r = !0, active: n = !1, tabStopId: c, ...u } = e, p = ue(), f = c || p, s = xe(we, o), v = s.currentTabStopId === f, l = j(o), { onFocusableItemAdd: $, onFocusableItemRemove: d } = s;
  return S(() => {
    if (r)
      return $(), () => d();
  }, [
    r,
    $,
    d
  ]), /* @__PURE__ */ i(G.ItemSlot, {
    scope: o,
    id: f,
    focusable: r,
    active: n
  }, /* @__PURE__ */ i(F.span, R({
    tabIndex: v ? 0 : -1,
    "data-orientation": s.orientation
  }, u, {
    ref: t,
    onMouseDown: x(e.onMouseDown, (a) => {
      r ? s.onItemFocus(f) : a.preventDefault();
    }),
    onFocus: x(
      e.onFocus,
      () => s.onItemFocus(f)
    ),
    onKeyDown: x(e.onKeyDown, (a) => {
      if (a.key === "Tab" && a.shiftKey) {
        s.onItemShiftTab();
        return;
      }
      if (a.target !== a.currentTarget) return;
      const b = ye(a, s.orientation, s.dir);
      if (b !== void 0) {
        a.preventDefault();
        let g = l().filter(
          (I) => I.focusable
        ).map(
          (I) => I.ref.current
        );
        if (b === "last") g.reverse();
        else if (b === "prev" || b === "next") {
          b === "prev" && g.reverse();
          const I = g.indexOf(a.currentTarget);
          g = s.loop ? Se(g, I + 1) : g.slice(I + 1);
        }
        setTimeout(
          () => z(g)
        );
      }
    })
  })));
}), Fe = {
  ArrowLeft: "prev",
  ArrowUp: "prev",
  ArrowRight: "next",
  ArrowDown: "next",
  PageUp: "first",
  Home: "first",
  PageDown: "last",
  End: "last"
};
function Ee(e, t) {
  return t !== "rtl" ? e : e === "ArrowLeft" ? "ArrowRight" : e === "ArrowRight" ? "ArrowLeft" : e;
}
function ye(e, t, o) {
  const r = Ee(e.key, o);
  if (!(t === "vertical" && [
    "ArrowLeft",
    "ArrowRight"
  ].includes(r)) && !(t === "horizontal" && [
    "ArrowUp",
    "ArrowDown"
  ].includes(r)))
    return Fe[r];
}
function z(e) {
  const t = document.activeElement;
  for (const o of e)
    if (o === t || (o.focus(), document.activeElement !== t)) return;
}
function Se(e, t) {
  return e.map(
    (o, r) => e[(t + r) % e.length]
  );
}
const ke = he, Ae = Ce, Y = "Radio", [Te, H] = D(Y), [Pe, Ge] = Te(Y), Ne = /* @__PURE__ */ h((e, t) => {
  const { __scopeRadio: o, name: r, checked: n = !1, required: c, disabled: u, value: p = "on", onCheck: f, ...s } = e, [v, l] = P(null), $ = N(
    t,
    (b) => l(b)
  ), d = w(!1), a = v ? !!v.closest("form") : !0;
  return /* @__PURE__ */ i(Pe, {
    scope: o,
    checked: n,
    disabled: u
  }, /* @__PURE__ */ i(F.button, R({
    type: "button",
    role: "radio",
    "aria-checked": n,
    "data-state": W(n),
    "data-disabled": u ? "" : void 0,
    disabled: u,
    value: p
  }, s, {
    ref: $,
    onClick: x(e.onClick, (b) => {
      n || f == null || f(), a && (d.current = b.isPropagationStopped(), d.current || b.stopPropagation());
    })
  })), a && /* @__PURE__ */ i(Me, {
    control: v,
    bubbles: !d.current,
    name: r,
    value: p,
    checked: n,
    required: c,
    disabled: u,
    style: {
      transform: "translateX(-100%)"
    }
  }));
}), De = "RadioIndicator", Oe = /* @__PURE__ */ h((e, t) => {
  const { __scopeRadio: o, forceMount: r, ...n } = e, c = Ge(De, o);
  return /* @__PURE__ */ i($e, {
    present: r || c.checked
  }, /* @__PURE__ */ i(F.span, R({
    "data-state": W(c.checked),
    "data-disabled": c.disabled ? "" : void 0
  }, n, {
    ref: t
  })));
}), Me = (e) => {
  const { control: t, checked: o, bubbles: r = !0, ...n } = e, c = w(null), u = le(o), p = be(t);
  return S(() => {
    const f = c.current, s = window.HTMLInputElement.prototype, l = Object.getOwnPropertyDescriptor(s, "checked").set;
    if (u !== o && l) {
      const $ = new Event("click", {
        bubbles: r
      });
      l.call(f, o), f.dispatchEvent($);
    }
  }, [
    u,
    o,
    r
  ]), /* @__PURE__ */ i("input", R({
    type: "radio",
    "aria-hidden": !0,
    defaultChecked: o
  }, n, {
    tabIndex: -1,
    ref: c,
    style: {
      ...e.style,
      ...p,
      position: "absolute",
      pointerEvents: "none",
      opacity: 0,
      margin: 0
    }
  }));
};
function W(e) {
  return e ? "checked" : "unchecked";
}
const Le = [
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight"
], X = "RadioGroup", [Ke, bo] = D(X, [
  B,
  H
]), J = B(), Q = H(), [Ue, Ve] = Ke(X), qe = /* @__PURE__ */ h((e, t) => {
  const { __scopeRadioGroup: o, name: r, defaultValue: n, value: c, required: u = !1, disabled: p = !1, orientation: f, dir: s, loop: v = !0, onValueChange: l, ...$ } = e, d = J(o), a = V(s), [b, C] = U({
    prop: c,
    defaultProp: n,
    onChange: l
  });
  return /* @__PURE__ */ i(Ue, {
    scope: o,
    name: r,
    required: u,
    disabled: p,
    value: b,
    onValueChange: C
  }, /* @__PURE__ */ i(ke, R({
    asChild: !0
  }, d, {
    orientation: f,
    dir: a,
    loop: v
  }), /* @__PURE__ */ i(F.div, R({
    role: "radiogroup",
    "aria-required": u,
    "aria-orientation": f,
    "data-disabled": p ? "" : void 0,
    dir: a
  }, $, {
    ref: t
  }))));
}), je = "RadioGroupItem", Be = /* @__PURE__ */ h((e, t) => {
  const { __scopeRadioGroup: o, disabled: r, ...n } = e, c = Ve(je, o), u = c.disabled || r, p = J(o), f = Q(o), s = w(null), v = N(t, s), l = c.value === n.value, $ = w(!1);
  return S(() => {
    const d = (b) => {
      Le.includes(b.key) && ($.current = !0);
    }, a = () => $.current = !1;
    return document.addEventListener("keydown", d), document.addEventListener("keyup", a), () => {
      document.removeEventListener("keydown", d), document.removeEventListener("keyup", a);
    };
  }, []), /* @__PURE__ */ i(Ae, R({
    asChild: !0
  }, p, {
    focusable: !u,
    active: l
  }), /* @__PURE__ */ i(Ne, R({
    disabled: u,
    required: c.required,
    checked: l
  }, f, n, {
    name: c.name,
    ref: v,
    onCheck: () => c.onValueChange(n.value),
    onKeyDown: x((d) => {
      d.key === "Enter" && d.preventDefault();
    }),
    onFocus: x(n.onFocus, () => {
      var d;
      $.current && ((d = s.current) === null || d === void 0 || d.click());
    })
  })));
}), ze = /* @__PURE__ */ h((e, t) => {
  const { __scopeRadioGroup: o, ...r } = e, n = Q(o);
  return /* @__PURE__ */ i(Oe, R({}, n, r, {
    ref: t
  }));
}), Z = qe, ee = Be, Ye = ze, He = pe(
  "aspect-square bg-white rounded-full border text-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "border-primary",
        secondary: "border-gray-neutral-100"
      },
      size: {
        md: "h-4 w-4",
        default: "h-5 w-5"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
), oe = K.forwardRef(({ className: e, ...t }, o) => /* @__PURE__ */ y.jsx(
  Z,
  {
    className: q("grid gap-2", e),
    ...t,
    ref: o
  }
));
oe.displayName = Z.displayName;
const te = K.forwardRef(({ className: e, size: t, variant: o, ...r }, n) => /* @__PURE__ */ y.jsx(
  ee,
  {
    ref: n,
    className: q(
      He({ size: t, variant: o }),
      e
    ),
    ...r,
    children: /* @__PURE__ */ y.jsx(Ye, { className: "flex items-center justify-center", children: /* @__PURE__ */ y.jsx(me, { name: "Circle", className: "h-3 w-3 fill-primary" }) })
  }
));
te.displayName = ee.displayName;
const lo = Object.assign(oe, {
  Item: te
});
export {
  lo as R
};
