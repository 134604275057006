import { j as e } from "./jsx-runtime-D0AQ1Mn7.js";
import { useState as m } from "react";
import { A as i } from "./AlertDialog.component-C7J7gpua.js";
import { B as g } from "./Badge-QBVLNL8O.js";
import { B as h } from "./Button-CAmI0_H8.js";
import { S as o } from "./Separator.component-CUuwnhk-.js";
import { S as f } from "./Stack-B4QZt_R0.js";
import { T as t } from "./Text-CwTWgi3Y.js";
import { F as u } from "./FolderIcon.component-ZdBsDMDe.js";
import { D as a } from "./DropdownMenu-8pjW7QUn.js";
import { I as l } from "./Icon-vWfhOtJ0.js";
import { c as d } from "./utils-CJ9afRe1.js";
const p = (s) => /* @__PURE__ */ e.jsxs(a, { children: [
  /* @__PURE__ */ e.jsx(a.Trigger, { asChild: !0, children: /* @__PURE__ */ e.jsxs(
    h,
    {
      variant: "ghost",
      size: "sm",
      className: "gap gap-1 bg-white",
      children: [
        /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "FilterList", width: 16, height: 16 }),
        s.active === "MY_LOANS" ? "My Loans" : "All Loans",
        /* @__PURE__ */ e.jsx(l, { strokeWidth: 1.5, name: "NavArrowDown", width: 16, height: 16 })
      ]
    }
  ) }),
  /* @__PURE__ */ e.jsxs(
    a.Content,
    {
      align: "end",
      side: "bottom",
      alignOffset: 0,
      className: "p-2 w-36",
      children: [
        /* @__PURE__ */ e.jsxs(
          a.Item,
          {
            onSelect: () => s.onFilterChange("MY_LOANS"),
            className: "flex gap-2",
            children: [
              /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "UserBag", width: 16, height: 16 }),
              " ",
              /* @__PURE__ */ e.jsx(t, { size: "sm", children: "My Loans" })
            ]
          }
        ),
        /* @__PURE__ */ e.jsxs(
          a.Item,
          {
            onSelect: () => s.onFilterChange("ALL_LOANS"),
            className: "flex gap-2",
            children: [
              /* @__PURE__ */ e.jsx(l, { strokeWidth: 2, name: "Handbag", width: 16, height: 16 }),
              " ",
              /* @__PURE__ */ e.jsx(t, { size: "sm", children: "All Loans" })
            ]
          }
        )
      ]
    }
  )
] }), O = (s) => {
  const [c, x] = m("MY_LOANS"), [r, j] = m(null);
  return /* @__PURE__ */ e.jsxs(
    i,
    {
      open: s.open,
      onOpenChange: s.onOpenChange,
      children: [
        /* @__PURE__ */ e.jsx(i.Overlay, {}),
        /* @__PURE__ */ e.jsxs(i.Content, { className: "sm:max-w-lg w-lg", children: [
          /* @__PURE__ */ e.jsx(i.VisuallyHidden, { children: /* @__PURE__ */ e.jsx(i.Title, { children: "Move to loan" }) }),
          /* @__PURE__ */ e.jsxs(t, { className: "px-3 pt-3", size: "sm", children: [
            "Move ",
            s.items.length,
            " items to loan"
          ] }),
          /* @__PURE__ */ e.jsx(o, {}),
          /* @__PURE__ */ e.jsx(i.Header, { className: "px-4", children: /* @__PURE__ */ e.jsxs(t, { size: "sm", as: "div", children: [
            "Current Location : ",
            /* @__PURE__ */ e.jsx(g, { variant: "secondary", children: "My Shoebox" })
          ] }) }),
          /* @__PURE__ */ e.jsx(o, {}),
          !s.hideLoanFilter && /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
            /* @__PURE__ */ e.jsx("div", { children: /* @__PURE__ */ e.jsx(p, { active: c, onFilterChange: x }) }),
            /* @__PURE__ */ e.jsx(o, {})
          ] }),
          /* @__PURE__ */ e.jsx(
            "div",
            {
              className: "px-4 flex-1 flex gap-6 sm:gap-3 flex-col sm:max-h-[200px] overflow-y-auto scrollbar-stable",
              children: /* @__PURE__ */ e.jsx(
                "div",
                {
                  className: d("flex-1 flex flex-col gap-3"),
                  children: s.loans.filter((n) => c === "MY_LOANS" ? n.iAmAdded : c === "ALL_LOANS").map((n) => /* @__PURE__ */ e.jsxs(
                    f,
                    {
                      onClick: () => j((r == null ? void 0 : r.id) === n.id ? null : n),
                      row: !0,
                      space: "sm",
                      className: d(
                        "px-3 py-2 group flex min-h-10 items-center hover:bg-gray-neutral-30 rounded-lg transition-colors cursor-pointer",
                        { "bg-gray-neutral-30": (r == null ? void 0 : r.id) === n.id }
                      ),
                      children: [
                        /* @__PURE__ */ e.jsx(u, { className: "shrink-0" }),
                        /* @__PURE__ */ e.jsx(t, { truncate: !0, size: "sm", className: "flex-1", children: n.projectName }),
                        /* @__PURE__ */ e.jsx(i.Cancel, { asChild: !0, children: /* @__PURE__ */ e.jsx(
                          h,
                          {
                            onClick: () => {
                              s.onMoveConfirm(
                                n,
                                s.items
                              );
                            },
                            variant: "secondary",
                            size: "sm",
                            className: "hidden sm:inline-flex hover:text-blue-100 opacity-0 group-hover:opacity-100 w-0 group-hover:w-auto group-hover:px-2 overflow-hidden p-0",
                            children: "Move here"
                          }
                        ) })
                      ]
                    },
                    n.id
                  ))
                }
              )
            }
          ),
          /* @__PURE__ */ e.jsx(o, {}),
          /* @__PURE__ */ e.jsxs(i.Footer, { className: "shrink-0", children: [
            /* @__PURE__ */ e.jsx(i.Cancel, { children: "Cancel" }),
            /* @__PURE__ */ e.jsx(
              i.Action,
              {
                onClick: () => r && s.onMoveConfirm(
                  r,
                  s.items
                ),
                disabled: !r,
                children: "Move"
              }
            )
          ] })
        ] })
      ]
    }
  );
};
export {
  p as L,
  O as P
};
